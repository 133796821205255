<template>
  <div>
    <a-modal
      v-model="visible"
      width="900px"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? $t("编辑货品") : $t("新增货品") }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-row gutter="16">
            <a-col :span="12">
              <a-form-model-item prop="number" :label="$t('货品编号')">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="name" :label="$t('货品名称')">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="barcode" :label="$t('货品条码')">
                <a-input v-model="form.barcode" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="spec" :label="$t('规格')">
                <a-input v-model="form.spec" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="english_name" :label="$t('英文名称')">
                <a-input v-model="form.english_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="category" :label="$t('分类')">
                <CategorySelect v-model="form.category" :name="form.category_name"></CategorySelect>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="12">
              <a-form-model-item prop="production_area" :label="$t('产地')">
                <a-input v-model="form.production_area" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="supplier" :label="$t('供应商')">
                <a-input v-model="form.supplier" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-model-item prop="unit_price" :label="$t('单价')">
                <a-input-number v-model="form.unit_price" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item prop="unit" :label="$t('单位')">
                <a-select v-model="form.unit" allowClear style="width: 100%">
                  <a-select-option v-for="item in $parent.unitItems" :key="item.id" :value="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="length" :label="$t('长度(厘米)')">
                <a-input-number v-model="form.length" style="width: 100%" @change="calculateVolume" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="width" :label="$t('宽度(厘米)')">
                <a-input-number v-model="form.width" style="width: 100%" @change="calculateVolume" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="height" :label="$t('高度(厘米)')">
                <a-input-number v-model="form.height" style="width: 100%" @change="calculateVolume" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="volume" :label="$t('体积(立方米)')">
                <a-input-number v-model="form.volume" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="weight" :label="$t('重量(千克)')">
                <a-input-number v-model="form.weight" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="enable_pallet" :label="$t('启用容器')">
                <a-select v-model="form.enable_pallet" style="width: 100%">
                  <a-select-option :value="true">{{ $t("开启") }}</a-select-option>
                  <a-select-option :value="false">{{ $t("关闭") }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col v-if="form.enable_pallet" :span="12">
              <a-form-model-item prop="pallet_capacity" :label="$t('容器容量')">
                <a-input-number v-model="form.pallet_capacity" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item prop="stock_out_strategy" :label="$t('出库策略')">
                <a-select v-model="form.stock_out_strategy" style="width: 100%">
                  <a-select-option value="fifo">{{ $t("先进先出(FIFO)") }}</a-select-option>
                  <a-select-option value="fefo">{{ $t("先到期先出(FEFO)") }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="enable_batch_control" :label="$t('批次控制')">
                <a-select v-model="form.enable_batch_control" style="width: 100%">
                  <a-select-option :value="true">{{ $t("开启") }}</a-select-option>
                  <a-select-option :value="false">{{ $t("关闭") }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="enable_shelf_life" :label="$t('启用保质期')">
                <a-select v-model="form.enable_shelf_life" style="width: 100%">
                  <a-select-option :value="true">{{ $t("开启") }}</a-select-option>
                  <a-select-option :value="false">{{ $t("关闭") }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.enable_shelf_life" :span="12">
              <a-form-model-item prop="shelf_life_days" :label="$t('保质期(天)')">
                <a-input-number v-model="form.shelf_life_days" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.enable_shelf_life" :span="12">
              <a-form-model-item prop="shelf_life_warning_days" :label="$t('预警天数')">
                <a-input-number v-model="form.shelf_life_warning_days" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item prop="enable_inventory_warning" :label="$t('启用库存警告')">
                <a-select v-model="form.enable_inventory_warning" style="width: 100%">
                  <a-select-option :value="true">{{ $t("开启") }}</a-select-option>
                  <a-select-option :value="false">{{ $t("关闭") }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.enable_inventory_warning" :span="12">
              <a-form-model-item prop="warning_warehouses" :label="$t('预警仓库')">
                <WarehouseMultSelect v-model="form.warning_warehouses" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.enable_inventory_warning" :span="12">
              <a-form-model-item prop="inventory_upper" :label="$t('库存上限')">
                <a-input-number v-model="form.inventory_upper" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col v-if="form.enable_inventory_warning" :span="12">
              <a-form-model-item prop="inventory_lower" :label="$t('库存下限')">
                <a-input-number v-model="form.inventory_lower" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="12">
              <a-form-model-item prop="is_active" :label="$t('是否激活货品')">
                <a-select v-model="form.is_active" style="width: 100%">
                  <a-select-option :value="true">{{ $t("激活") }}</a-select-option>
                  <a-select-option :value="false">{{ $t("冻结") }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="form.remark" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="remark" :label="$t('图片')">
                <MaterialImageUpload v-model="form.images" :initialItems="form.image_items" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { materialCreate, materialUpdate } from "@/api/material";
import NP from "number-precision";
import rules from "./rules.js";

export default {
  components: {
    CategorySelect: () => import("@/components/CategorySelect/"),
    MaterialImageUpload: () => import("@/components/MaterialImageUpload/"),
    WarehouseMultSelect: () => import("@/components/WarehouseMultSelect"),
  },
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      if (
        this.form.inventory_upper != undefined &&
        this.form.inventory_lower != undefined &&
        this.form.inventory_upper < this.form.inventory_lower
      ) {
        this.$message.error(this.$t("库存下限不能大于库存上限"));
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? materialUpdate : materialCreate;
          func(this.form)
            .then((data) => {
              this.$message.success(this.form.id ? this.$t("修改成功") : this.$t("新增成功"));
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
    calculateVolume() {
      const length = this.form.length || 0;
      const width = this.form.width || 0;
      const height = this.form.height || 0;
      this.form.volume = NP.times(length, width, height, 0.000001);
    },
  },
};
</script>

<style scoped></style>
